@tailwind base;
@tailwind components;
@tailwind utilities;


/* Hamburger Animation */
.open #hamburger{
    transform: translateX(-50px);
    background-color: transparent;
}

.open #hamburger::after,
.open #hamburger::before{
    background-color: #DDDDDD;
}

.open #hamburger::before{
    transform: rotate(45deg) translate(35px, -35px);
}

.open #hamburger::after{
    transform: rotate(-45deg) translate(35px, 35px);
}

/* portfolio porject list  */

.customstyle::-webkit-scrollbar{
    display: none;
}

.customstyle {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


/* header Animation  */
.headerAnimation{
    opacity: 0;
    animation: Line 1000ms ease-in-out 0s forwards;
}

/* Hero Animation */
.firstAnimation{
    opacity: 0;
    animation: Starter 1000ms ease-in-out 500ms forwards;
}

.secondAnimation{
    opacity: 0;
    animation: Starter 1000ms ease-in-out 700ms forwards;
}

/* services Animation */
.thirdAnimation{
    opacity: 0;
    animation: Starter 1000ms ease-in-out 1000ms forwards;
}

/* servicesCard Animation */
.fourthAnimation{
    opacity: 0;
    animation: Starter 1000ms ease-in-out 1500ms forwards;
}

/* recent projects Animation */
.fifthAnimation{
    opacity: 0;
    animation: Starter 1000ms ease-in-out 2000ms forwards;
}

/* recent projectCard Animation */
.sixthAnimation{
    opacity: 0;
    animation: Starter 1000ms ease-in-out 2500ms forwards;
}

/* footer Animation  */
.footerAnimation{
    opacity: 0;
    animation: Line 500ms ease-in 0s forwards;
}

@keyframes Starter {
    from{
        opacity: 0;
        transform: translateY(-50px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes Line {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}